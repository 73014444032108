<template>
  <div class="createApp">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          label-position="right"
          label-width="100px"
          :model="formInline"
          style="width: 440px"
          :rules="rules"
          ref="form"
        >
          <h1>基础信息</h1>
          <el-form-item label="官网目录" prop="filePath">
            <el-input
              type="text"
              v-model="formInline.filePath"
              placeholder="请输入官网目录"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <el-form-item label="apk名称" prop="fileName">
            <el-input
              type="text"
              v-model="formInline.fileName"
              placeholder="请输入apk名称"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <h1>FTP服务器信息</h1>
          <el-form-item label="IP地址" prop="ipAddress">
            <el-input
              type="text"
              v-model="formInline.ipAddress"
              placeholder="请输入IP地址"
              maxlength="15"
            ></el-input>
          </el-form-item>
          <el-form-item label="端口" prop="port">
            <el-input
              type="text"
              v-model="formInline.port"
              placeholder="请输入端口"
              maxlength="5"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="userName">
            <el-input
              type="text"
              v-model="formInline.userName"
              placeholder="请输入用户名"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              type="password"
              v-model="formInline.password"
              autocomplete="new-password"
              placeholder="请输入密码"
              maxlength="36"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="submitData" :loading="loading"
            >确认同步</el-button
          >
          <el-button type="info" @click="$emit('dialogFtpInfoShow', false)"
            >取消</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["applicationId"],
  data() {
    let validateIpAddress = (rule, value, callback) => {
      let pattern =
        /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/g;
      if (value === "") {
        callback(new Error("请输入IP地址"));
      } else if (pattern.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的IP地址"));
      }
    };
    let validatePort = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入端口号"));
      } else if (value > 1 && value < 65535) {
        callback();
      } else {
        callback(new Error("请输入正确的端口号"));
      }
    };
    return {
      loading: false,
      appVersionIdType: "",
      command: "",
      fileUrl: "",
      startTime: "",
      formInline: {
        ipAddress: "",
        port: "",
        userName: "",
        password: "",
        fileName: "",
        filePath: "",
      },
      rules: {
        ipAddress: [
          { required: true, validator: validateIpAddress, trigger: "blur" },
        ],
        port: [{ required: true, validator: validatePort, trigger: "blur" }],
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        fileName: [
          { required: true, message: "请输入apk名称", trigger: "blur" },
        ],
        filePath: [
          { required: true, message: "请输入官网目录", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getInfo() {
      this.$axios
        .get("/acb/2.0/application/ftp/queryDetail", {
          data: {
            applicationId: this.applicationId,
          },
        })
        .then((res) => {
          let newDetail = res.value;
          for (let key in newDetail) {
            // eslint-disable-next-line no-prototype-builtins
            if (newDetail.hasOwnProperty(key) && newDetail[key]) {
              this.formInline[key] = newDetail[key];
            }
          }
          this.formInline.password = "";
        });
    },
    submitData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios
            .post(
              "/appmanager/application/ftp/replace",
              {
                data: {
                  ...this.formInline,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
                timeout: 30000,
              }
            )
            .then((res) => {
              this.loading = false;
              if (res.state === 0) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.$emit("dialogFtpInfoShow", false);
                this.$emit("searchData");
              } else {
                this.$alert(res.desc);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  created() {
    this.getInfo();
  },
  mounted() {},
  computed: {},
};
</script>
