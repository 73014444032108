<template>
  <div class="AppManage">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <!-- 放入搜索条件 -->
            </div>
            <div class="col_right"></div>
          </div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-show="createAppBtn"
                @click="dialogFormVisible = true"
                >创建新应用</el-button
              >
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" align="center" :label="$t('list.index')" width="50"> </el-table-column>
          <el-table-column
            align="center"
            show-overflow-tooltip
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
          <el-table-column align="center" label="操作" width="90px">
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">版本管理 </el-dropdown-item>
                  <el-dropdown-item
                    command="2"
                    v-show="
                      (scope.row.syncStatus === 0 || scope.row.syncStatus === 3) &&
                      scope.row.platform !== 1
                    "
                  >
                    同步至官网
                  </el-dropdown-item>
                  <el-dropdown-item
                    command="3"
                    v-show="
                      (scope.row.syncStatus === 0 || scope.row.syncStatus === 3) &&
                      scope.row.platform !== 1 &&
                      scope.row.versionName
                    "
                  >
                    补丁管理
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper" v-show="total > 0">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <transition name="fade">
        <div v-if="dialogFormVisible || dialogFtpInfo">
          <el-dialog
            title=""
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            width="600px"
          >
            <create-app @dialogShow="dialogShow" @searchData="searchData"></create-app>
          </el-dialog>
          <el-dialog
            title=""
            :visible.sync="dialogFtpInfo"
            class="dialogFtpInfo"
            :close-on-click-modal="false"
            width="600px"
          >
            <ftp-info
              :applicationId="applicationId"
              @dialogFtpInfoShow="dialogFtpInfoShow"
              @searchData="searchData"
            ></ftp-info>
          </el-dialog>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import CreateApp from "./createApp";
import FtpInfo from "./ftpInfo";
export default {
  name: "AppManage",
  components: {
    CreateApp,
    FtpInfo,
  },
  data() {
    return {
      applicationId: "",
      dialogFtpInfo: false,
      dialogFormVisible: false,
      // createAppBtn: sessionStorage.isAdmin === "1",
      createAppBtn: true,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      tableData: [],
      formInline: {
        title: "",
        jumpType: "",
        state: "",
        applicationName: "",
      },
      tableCols: [
        {
          label: "应用名称",
          prop: "applicationName",
          width: "140",
        },
        {
          label: "包名&BundleId",
          prop: "productId",
          width: "180",
        },
        {
          label: "平台",
          prop: "platform",
          width: "100",
          formatter: (row) => {
            if (row.platform === 0) {
              return "Android";
            } else if (row.platform === 1) {
              return "iOS";
            }
          },
        },
        {
          label: "生效版本号",
          prop: "versionName",
          width: "100",
        },
        {
          label: "描述",
          prop: "applicationDesc",
        },
        {
          label: this.$t("list.add_time"),
          prop: "createTime",
          width: "160",
        },
        {
          label: "官网同步状态",
          prop: "syncStatus",
          width: "120",
          formatter: (row) => {
            if (row.syncStatus === 0) {
              return "未同步";
            } else if (row.syncStatus === 1) {
              return "同步中";
            } else if (row.syncStatus === 2) {
              return "同步成功";
            } else if (row.syncStatus === 3) {
              return "同步失败";
              // return '同步失败' + ' 原因：' + row.syncDesc
            } else {
              return "";
            }
          },
        },
        {
          label: "创建人",
          prop: "promulgatorName",
          width: "100",
        },
      ],
    };
  },
  methods: {
    dialogShow(value) {
      this.dialogFormVisible = value;
    },
    dialogFtpInfoShow(value) {
      this.dialogFtpInfo = value;
    },
    handleCommand(command, row) {
      if (command === "1") {
        this.toUpdateRecord(row);
      } else if (command === "2") {
        this.applicationId = row.applicationId;
        this.dialogFtpInfo = true;
      } else {
        this.$router.push({
          path: "/patchAndroidRecord",
          query: {
            applicationId: row.applicationId,
            versionName: row.versionName,
          },
        });
      }
    },
    // 操作细则
    toUpdateRecord(row) {
      if (row.platform === 1) {
        this.$router.push({
          path: "/updateIOSRecord",
          query: { applicationId: row.applicationId },
        });
      } else {
        this.$router.push({
          path: "/updateAndroidRecord",
          query: { applicationId: row.applicationId },
        });
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      this.tableData = [];
      this.$axios
        .get("/acb/2.0/application/version/queryList", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  created() {
    this.searchData();
  },
  activated() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to { /* .fade-leave-active in below version 2.1.8 */
  opacity: 0;
}

.dialogFtpInfo >>> .el-dialog__body {
  padding-top: 10px;
}

.dialogFtpInfo >>> .el-dialog__body h1 {
  margin-bottom: 20px;
}
</style>
